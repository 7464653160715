import React, { Component } from 'react'
import { createStyles, withStyles, Container } from '@material-ui/core'

import Plan from './Plan'

const styles = (theme) =>
  createStyles({
    container: {
      maxWidth: '49.625rem',
    },
    list: {
      paddingLeft: 0,
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  })

class Plans extends Component {
  render() {
    const { classes } = this.props
    return (
      <Container className={classes.container}>
        <ul className={classes.list}>
          <Plan
            title="Monthly Plan"
            description="See if Avocado Finance is right for you"
            price="6.99"
          />
          <Plan
            discount="28"
            title="Annual Plan"
            description="Billed at $59.99 annually"
            price="4.99"
          />
        </ul>
      </Container>
    )
  }
}

export default withStyles(styles)(Plans)
