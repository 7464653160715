import React, { Component } from 'react'
import {
  Container,
  Typography,
  createStyles,
  withStyles,
} from '@material-ui/core'

const styles = (theme) => {
  return createStyles({
    section: {
      marginTop: '5.4375rem',
      [theme.breakpoints.up('md')]: {
        marginTop: '10.8125rem',
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: '10.6875rem',
      },
    },
    container: {
      paddingLeft: '2rem',
      paddingRight: '2.625rem',
      [theme.breakpoints.up('md')]: {
        maxWidth: '46.1875rem',
        padding: 0,
      },
    },
    title: {
      marginBottom: '2.25rem',
      fontWeight: 700,
    },
    titleBottom: {
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
  })
}

class Explanation extends Component {
  render() {
    const { classes } = this.props
    return (
      <section className={classes.section}>
        <Container className={classes.container}>
          <Typography variant="h3" className={classes.title} align="center">
            Why is there no
            <span className={classes.titleBottom}> free plan?</span>
          </Typography>
          <Typography>
            Charging a subscription aligns our incentives with yours and enables
            us to exclusively focus on improving your financial life. It also
            means that we will never clutter the product with ads, try to sell
            you a credit card you don’t need, or auction off your financial data
            to the highest bidder.{' '}
          </Typography>
        </Container>
      </section>
    )
  }
}

export default withStyles(styles)(Explanation)
