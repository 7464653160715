import React, { Component, useEffect } from 'react'
import Head from 'react-helmet'

import Hero from './Hero'
import Plans from './Plans'
import Explanation from './Explanation'
import FinalizingPrice from './FinalizingPrice'
import Layout from '../components/Layout'

export const Pricing = () => {
  // useEffect(() => {
  //   const baseUrl = window.location.origin
  //   window.location.replace(`${baseUrl}`);
  // }, [])

  // return null
  return (
    <Layout>
      <Head>
        <title>Pricing</title>
      </Head>
      <Hero />
      <Plans />
      {/* <FinalizingPrice /> */}
      {/* <Explanation /> */}
    </Layout>
  )
}

export default Pricing
