import React, { Component } from 'react'
import {
  Container,
  Typography,
  createStyles,
  withStyles,
} from '@material-ui/core'

import SignUp from '../Home/Hero/Contact'

const styles = (theme) => {
  return createStyles({
    section: {
      marginTop: '5.4375rem',
      [theme.breakpoints.up('md')]: {
        marginTop: '5.8125rem',
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: '5.8125rem',
      },
    },
    container: {
      paddingLeft: '2rem',
      paddingRight: '2.625rem',
      [theme.breakpoints.up('md')]: {
        maxWidth: '46.1875rem',
        padding: 0,
      },
    },
    title: {
      marginBottom: '2.25rem',
      fontWeight: 700,
    },
    titleBottom: {
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    parentSignUp: {
      margin: '0 auto',
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
        width: '505px',
      },
      [theme.breakpoints.up('lg')]: {
        display: 'block',
        width: '505px'
      },
    }
  })
}

class FinalizingPrice extends Component {
  render() {
    const { classes } = this.props
    return (
      <section className={classes.section}>
        <Container className={classes.container}>
          <Typography variant="h3" className={classes.title} align="center">
            We are finalizing the pricing
          </Typography>
          <Typography>
            Avocado Finance will offer a free trial to all first-time users. You won’t be charged until the end of the trial. We want you to use our app, see if it’s useful for you, and subscribe if you think it truly benefits your financial life. Sign up for our waitlist to receive an email when we launch!
          </Typography>
          <br />
          <br />
          <br />
          <div className={classes.parentSignUp}>
            <SignUp />
          </div>
        </Container>
      </section>
    )
  }
}

export default withStyles(styles)(FinalizingPrice)

