import React, { Component } from 'react'
import {
  Typography,
  Button,
  createStyles,
  withStyles,
  Container,
} from '@material-ui/core'
import { getHeroStyles } from '../styles/hero'

const styles = (theme) => {
  const heroStyles = getHeroStyles()
  return createStyles({
    container: {
      maxWidth: '35rem',
      ...heroStyles,
      marginBottom: '8.875rem',
      textAlign: 'center',
    },
    button: {
      padding: '0.8988rem 2rem',
      borderRadius: '6.25rem',
      marginTop: '1.5625rem',
    },
    title: {
      color: '#F0EDEF',
      marginBottom: '4.1875rem',
      [theme.breakpoints.up('md')]: {
        marginBottom: '4rem',
      },
    },
  })
}

class Hero extends Component {
  render() {
    const { classes } = this.props
    return (
      <Container className={classes.container}>
        <section>
          <Typography variant="h3" className={classes.title}>
            Try it free for 7 days
          </Typography>
          <Typography variant="subtitle1">
            If you love Avocado Finance by the end of the trial, you can pick
            one of the plans below.
          </Typography>
          <Button
            href="#"
            className={classes.button}
            variant="contained"
            color="primary"
          >
            Start my free trial
          </Button>
        </section>
      </Container>
    )
  }
}

export default withStyles(styles)(Hero)
