import React, { Component } from 'react'
import { Typography, createStyles, withStyles } from '@material-ui/core'

const styles = (theme) =>
  createStyles({
    card: {
      listStyle: 'none',
      borderRadius: '1.875rem',
      border: '1px solid #F7F7F7',
      boxShadow: '0px 4px 15px rgba(225, 225, 225, 0.5)',
      padding: '3.1875rem 1.5rem 2.8125rem',
      position: 'relative',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'stretch',
      backgroundColor: '#2B2B2B',
      [theme.breakpoints.up('md')]: {
        padding: '4.4375rem 1.875rem 3.375rem',
        width: '23.4375rem',
      },
      '& + &': {
        marginTop: '8.75rem',
        [theme.breakpoints.up('md')]: {
          marginTop: 0,
          marginLeft: '2.75rem',
        },
      },
    },
    body: {
      marginBottom: '5.5625rem',
      [theme.breakpoints.up('md')]: {
        marginBottom: '5.4375rem',
      },
    },
    title: {
      marginBottom: '2rem',
      fontWeight: 700,
      fontSize: '1.625rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.875rem',
        marginBottom: '2.1875rem',
      },
    },
    description: {
      color: '#F0EDEF',
      fontSize: '22px',
      [theme.breakpoints.up('md')]: {
        fontSize: '18px',
      }
    },
    price: {
      marginTop: 'auto',
    },
    amount: {
      color: '#F0EDEF',
      fontSize: '2.875rem',
      lineHeight: '132%',
      marginBottom: '0.1875rem',
      fontWeight: 'bold',
      [theme.breakpoints.up('md')]: {
        fontSize: '3.125rem',
      },
    },
    frequency: {
      fontSize: '1rem',
      fontWeight: 600,
      color: '#F0EDEF',
    },
    discount: {
      width: '7.5625rem',
      height: '1.5rem',
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      zIndex: 2,
      borderRadius: '6.25rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.primary.main,
      padding: '0.3125rem 0',
    },
    discountText: {
      lineHeight: '132%',
      letterSpacing: '0.03em',
      color: theme.palette.primary.contrastText,
      fontWeight: 'bold',
      fontSize: '0.875rem',
    },
  })

class Plan extends Component {
  render() {
    const { classes, title, description, price, discount } = this.props
    return (
      <li xs={12} md={5} className={classes.card}>
        {discount && (
          <div className={classes.discount}>
            <Typography className={classes.discountText}>
              Save {discount}%
            </Typography>
          </div>
        )}
        <div className={classes.body}>
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>
          <Typography className={classes.description}>{description}</Typography>
        </div>
        <div className={classes.price}>
          <Typography className={classes.amount}>${price}</Typography>
          <Typography className={classes.frequency}>per month</Typography>
        </div>
      </li>
    )
  }
}

export default withStyles(styles)(Plan)
